import { postData } from "../services/requests";
import { formValidate } from "../services/formValidate";
import { calcScroll } from "../services/calcScroll";

const order = () => {
  const form = $("form");
  const scroll = calcScroll();

  const messageLanguage = {
    fr: {
      loading: {
        color: "#fae100",
        title: "Envoi en cours...",
        subtitle: "Veuillez patienter pendant la soumission du formulaire",
      },
      success: {
        color: "#57cc09",
        title: "Merci pour votre soumission!",
        subtitle: "Notre responsable vous contactera sous peu.",
      },
      failure: {
        color: "#f14216",
        title: "Quelque chose s'est mal passé...",
        subtitle:
          "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer plus tard.",
      },
    },
    en: {
      loading: {
        color: "#fae100",
        title: "Sending in progress...",
        subtitle: "Please wait while the form is being submitted",
      },
      success: {
        color: "#57cc09",
        title: "Thank you for your submission!",
        subtitle: "Our manager will contact you shortly.",
      },
      failure: {
        color: "#f14216",
        title: "Something went wrong...",
        subtitle:
          "There was an error while submitting the form. Please try again later.",
      },
    },
    ru: {
      loading: {
        color: "#fae100",
        title: "Отправка формы...",
        subtitle: "Пожалуйста, подождите окончания операции",
      },
      success: {
        color: "#57cc09",
        title: "Спасибо за Ваш заказ!",
        subtitle: "Я свяжусь с Вами по предоставленным контактам.",
      },
      failure: {
        color: "#f14216",
        title: "Что-то пошло не так...",
        subtitle:
          "При отправке формы произошла ошибка. Пожалуйста, повторите попытку позже.",
      },
    },
  };

  $(form).submit(function (e) {
    e.preventDefault();

    let error = formValidate(this);
    //console.log(error);

    // Отримати id поточної форми
    const formId = $(this).attr("id");
    //Для форми з онлайн записом на дату-час
    let date;
    let time;
    if (formId === "form-booking") {
      date = localStorage.getItem("date");
      time = localStorage.getItem("time");
      if (!time || !date) {
        error = +1;
        //console.log(error);
        $("form .form__select").css("border-color", "red");
      } else {
        $("form .form__select").css("border-color", "#ccc");
      }
    }

    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }

    let message = messageLanguage[language];
    if (error === 0) {
      thanksModalInit();
      textThanksModal(message.loading);
      const formData = new FormData(this);

      const jsonData = Object.fromEntries(formData.entries());

      if (formId === "form-booking") {
        jsonData.orderDate = date;
        jsonData.orderTime = time;
      }
      jsonData.language = language;

      if (jsonData.data) {
        changeDataView(jsonData); //форматування вигляду дати
      }
      jsonData.pageInfo = window.location.href;
      recordDate(jsonData); //додаємо рядок з датою заявки
      const jsonString = JSON.stringify(jsonData);

      postData("smart.php", jsonString)
        .then((res) => {
          //console.log(res);
          dataLayer.push({
            event: "formSubmission", // Подія відправки форми (Треба для Google Tag Manager)
          });
          textThanksModal(message.success);
          $(form).trigger("reset");
          $("form .form__select__item.active").fadeOut();
          localStorage.removeItem("time");
        })
        .catch((error) => {
          console.log(error);
          textThanksModal(message.failure);
        });
    }
  });

  function thanksModalInit() {
    $("body").css({
      overflow: "hidden",
      "margin-right": `${scroll}px`,
    });
    $(".thanks").css("display", "flex");
    $(".thanks").addClass("animate__animated animate__fadeIn");
    $(".thanks__close").on("click", () => {
      closeThanks();
    });
    $(".thanks").on("click", (e) => {
      if ($(e.target).is(".thanks")) {
        closeThanks();
      }
    });
  }

  function textThanksModal(message) {
    $(".thanks__title").text(message.title);
    $(".thanks__subtitle").text(message.subtitle);
    $(".thanks__line").css("background-color", message.color);
    $(".thanks__modal").css("box-shadow", `0px 2px 8px ${message.color}`);
    setTimeout(() => {
      closeThanks();
    }, 10000);
  }

  function closeThanks() {
    $(".thanks").addClass("animate__fadeOut");
    $("body").css({
      overflow: "",
      "margin-right": "0",
    });
    setTimeout(() => {
      $(".thanks").css("display", "none");
      $(".thanks").removeClass("animate__fadeOut");
    }, 700);
  }

  function changeDataView(object) {
    const rawDate = $("#formDate").val(); // Отримуємо рядкове значення з інпуту з датою
    const dateParts = rawDate.split("-"); // Розбиваємо рядок на частини
    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Форматуємо дату
    object.date = formattedDate; // Замінюємо значення в об'єкті
  }

  function recordDate(object) {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    object.dateTime = `${formattedDate} ${formattedTime}`;
  }
};

export { order };
